import { s, c } from '../../val.config';
import { themeSchema } from '../schema/theme.val';

export const schema = s.object({
  title: s.string(),
  metadataTitle: s.string(),
  metadataDescription: s.string(),
  navLinks: s.array(s.object({ title: s.string(), url: s.string().raw() })),
  contactLinkTitle: s.string(),
  theme: themeSchema,
});

export default c.define('/content/pages/forsida.val.ts', schema, {
  theme: `dark`,
  title: 'Spesialister på digital produktutvikling',
  metadataTitle: 'Spesialister på digital produktutvikling',
  metadataDescription:
    'Spesialister på digital produktutvikling og et litt annerledes konsulentselskap i Oslo. Designere og teknologer som lager bra greier for kundene våre og oss selv.',
  navLinks: [
    { url: '/digital-produktutvikling', title: 'om blank' },
    { url: '/prosjekter', title: 'arbeider' },
    { url: '/jobb', title: 'jobb i blank' },
    { url: '/menneskene', title: 'folk i blank' },
    { url: '/handboka', title: 'håndbok' },
  ],
  contactLinkTitle: 'kontakt',
});
